import {Component, OnInit, Injector, OnDestroy} from '@angular/core';
import {EnoteService} from '../../services/enote.service';
import {Term} from '../enote_models/term';
import {Template} from '../enote_models/template';
import {Control} from '../enote_models/control';
import {DocumentsService} from '../../services/document.service';
import {SpecialtySearchService} from '../../services/specialty-search.service';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {VitalsService} from '../../services/vitals.service';
import {PatientVital} from '../../routes/practice-site/vital/PatientVital';
import {Appointment} from '../../health/models/appointment';
import {Tab} from '../../tabs/tab';
import {TabsService} from '../../services/tabs.service';
import {VitalPdfPrinterComponent} from '../../routes/practice-site/vital/vital-pdf-printer/vital-pdf-printer.component';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {RichTextTemplate} from '../../routes/rich-text-template-index/rich-text-template';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-enote',
  templateUrl: './enote.component.html',
  styleUrls: ['./enote.component.scss']
})
export class EnoteComponent implements OnInit, OnDestroy {

  public term = new Term('', '');
  public template = new Template();
  public show_error = false;
  docTypes: any[];
  public specialties: Observable<any[]>;
  public asyncSelected = '';
  public patientVital: PatientVital;
  public appointment: Appointment;
  public template_copy = false;
  public message: string;
  public error_message: string;
  public show_tempalte_name_error = false;
  public show_document_type_error = false;
  public show_specialty_error = false;
  public show_save_button = true;
  public isProgramOrPatientProfile = false;
  public saving = false;
  public show = false;
  public source_type: string;
  public patient_name: string;
  public source_id: number;
  public patient_id: number;
  public doctor_id: any;
  public stage: string;
  public is_rich_text: boolean;
  public selectedRichTextTemplate: RichTextTemplate;
  public selectedRichTextSubscription1: Subscription;
  public viewRichText: boolean;
  public ui: boolean;
  constructor(public _enote_service: EnoteService, private injector: Injector, private _tabs_service: TabsService,
              private documentService: DocumentsService, private vitalsService: VitalsService, public userService: UserService,
              private specialtySearchService: SpecialtySearchService,  private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.specialties = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this._enote_service.template.specialtyName);
    }).pipe(mergeMap((token: string) => this.getSpecialties(token)));
    this.selectedRichTextSubscription1 = this._enote_service.reloadRichTextTemplate.subscribe(item => {
      if (this.selectedRichTextTemplate.id == item.id) {
        this.selectedRichTextTemplate = new RichTextTemplate().load_from_json(item);
      }
    });
  }

  ngOnDestroy(): void {
    this.selectedRichTextSubscription1.unsubscribe();
  }

  getSpecialties(term: any) {
    return this.specialtySearchService.search(term);
  }

  typeaheadOnSelect(event) {
    this._enote_service.template.specialtyID = event.item.id;
  }
  typeaheadNoResults(event: boolean): void {
    delete this._enote_service.template.specialtyID
  }
  ngOnInit() {
    this._enote_service.getDefaultControls();
    this.getDocTypes();
    let template = this.injector.get('template', null);
    this.stage = this.injector.get('stage', null);
    this.source_id = this.injector.get('source_id', null);
    this.source_type = this.injector.get('source_type', null);
    this.patient_name = this.injector.get('patient_name', null);
    this.patient_id = this.injector.get('patient_id', null);
    this.doctor_id = this.injector.get('doctor_id', null);
    this.is_rich_text = this.injector.get('is_rich_text', null);
    this.selectedRichTextTemplate = this.injector.get('selectedRichTextTemplate', new RichTextTemplate());
    this.viewRichText = this.injector.get('viewRichText', null);
    this.isProgramOrPatientProfile = this.injector.get('isProgramOrPatientProfile', null);
    this.ui = this.injector.get('ui', false);
    if (this.source_type === 'Appointment') {
      this.vitalsService.getAppointmentLatestVitals(this.source_id)
        .then(resp => this.patientVital = new PatientVital().load_from_json(resp));
    }
    if (this.stage === 'new_temp') {
      if (template) {
        this.template_copy = true;
        this._enote_service.getTemplate(template)
          .then(resp => {
            delete this._enote_service.template.id;
            delete this._enote_service.template.name;
            this.show = true;
          });
      }
      else {
        delete this._enote_service.template;
        this._enote_service.template = new Template();
        this._enote_service.template.stage = this.stage;
        this.addInitialTerm();
        this.show = true;
      }
    }
    else {
      if (this.stage !== 'edit_temp' && this.stage !== 'read_only_template' && this.stage !== 'richText') {
        this._enote_service.template.source_id = this.source_id;
        this._enote_service.template.source_type = this.source_type;
        this._enote_service.template.template_id = template;
        this._enote_service.template.stage = this.stage;
        this.show = true;

      }
      if (!this._enote_service.template || this._enote_service.template.id != template) {
        this._enote_service.getTemplate(template).then(resp => {
          this._enote_service.template.stage = this.stage;
          this.show = true;
        })
      }
      if (this._enote_service.template || this.stage === 'richText') {
        this.show = true;
      }
    }
    if (this._enote_service.template) {
      this._enote_service.template.patient_id = this.patient_id;
      this._enote_service.template.signed_by_id = this.userService.current_user.id;
    }
    this.fetch_custom_controls();

  }
  getDocTypes() {
    this.documentService.getDocumentsType().then(resp => this.docTypes = resp)
  }

  fetch_custom_controls() {
    this._enote_service.fetch_data()
      .then(resp => {
        this._enote_service.custom_controls = resp.custom_controls
          .map(custom_control => new Control('', '')
            .load_from_json(custom_control))
      }).catch(resp => {
      console.log(resp)
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  addNewComponentTerm() {
    if (this._enote_service.template.isNote()) {
      return;
    }
    this.addInitialTerm();
  }


  addInitialTerm() {
    let new_term = new Term('', '');
    new_term.initial_component = true;
    let open_text_term = new Term('', '');
    open_text_term.is_open_text = true;
    new_term.addToChildren(open_text_term, 0);
    new_term.splitChildren();
    this._enote_service.templateTermsListing().push(new_term);
  }

  setTimeOutFailsMessage(): any {
    this.show_error = true;
    setTimeout(() => {
      this.show_error = false;
      this.show_specialty_error = false;
      this.show_document_type_error = false;
      this.show_tempalte_name_error = false;
    }, 3000)
  }

  save_template() {
    if (this._enote_service.template.name === '' || this._enote_service.template.name === undefined || this._enote_service.template.documentTypeID == undefined || this._enote_service.template.specialtyID == undefined) {
      if (this._enote_service.template.name === '' || this._enote_service.template.name === undefined) {
        this.show_tempalte_name_error = true;
      }
      if (this._enote_service.template.documentTypeID === undefined) {
        this.show_document_type_error = true;
      }
      if (this._enote_service.template.specialtyID === undefined) {
        this.show_specialty_error = true;
      }
      this.setTimeOutFailsMessage();
      return;
    }
    this.show_save_button = false;
    if (this._enote_service.template.id) {
      this._enote_service.update(this._enote_service.template).then(resp => {
        this.afterSave();
      }).catch(resp => {
        this.error_message = resp.error.message;
      });
    }
    else {
      this._enote_service.create(this._enote_service.template, this.template_copy).then(resp => {
        this.afterSave();
      }).catch(resp => {
        this.error_message = resp.error.message;
      });
    }
  }

  sign_template(stage) {
    this.saving = true;
    let type = this._enote_service.template.temp_type;
    let t_stage = this._enote_service.template.stage;
    if (this._enote_service.template.stage === 'drafted_enote') {
      this._enote_service.template.stage = stage;
      this.stage = stage;
      this._enote_service.template.temp_type = 'enote';
      this._enote_service.update_drafted_enote(this._enote_service.template).then(resp => {
        this.saving = false;
        this.afterSave()
      }).catch(resp => {
        this.saving = false;
        this.globalErrorHandlerService.error = resp.error.error;
        this._enote_service.template.stage = t_stage;
        this.stage = t_stage;
        this._enote_service.template.temp_type = type;
      });
    }
    else {
      this._enote_service.template.stage = stage;
      this.stage = stage;
      this._enote_service.template.temp_type = 'enote';
      this._enote_service.create_note(this._enote_service.template).then(resp => {
        this.saving = false;
        this.afterSave()
      }).catch(resp => {
        this.saving = false;
        this._enote_service.template.stage = t_stage;
        this.stage = t_stage;
        this._enote_service.template.temp_type = type;
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
  }

  save_as_draft(stage) {
    this.saving = true;
    if (this._enote_service.template.stage === 'drafted_enote') {
      this._enote_service.update_drafted_enote(this._enote_service.template).then(resp => {
        this.saving = false;
        this.afterSave()
      }).catch(resp => {
        console.log(resp)
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
    else {
      this._enote_service.template.stage = 'drafted_enote';
      this.stage = 'drafted_enote';
      this._enote_service.template.temp_type = 'drafted_enote';
      this._enote_service.create_note(this._enote_service.template).then(resp => {
        this.saving = false;
        this.afterSave()
      }).catch(resp => {
        console.log(resp)
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
  }

  afterSave() {
    const data = this.ui
      ? { templateId: this._enote_service.template.id, stepId: this.source_id }
      : 1;
    this._enote_service.reloadEnotes.next(data);
    setTimeout(() => {
      this.show_save_button = true;
    }, 5000);
  }


  delete_draft() {
    this.saving = true;
    this._enote_service.delete_drafted_enote().then(resp => {
      this._enote_service.reloadEnotes.next(1);
      this.message = 'Deleted Successfully';
      this.saving = false;
      setTimeout(() => {
        this.message = ''
      }, 5000)
    });
  }

  add_enote_printer_to_tabs(text) {
    let source_id = this.source_id;
    let title = (this.patient_name ? this.patient_name + ' - ' : '') + text
    if (this.source_type !== 'ProgrammeEnrolment') {
      this._tabs_service.onlyCloseTab(this._tabs_service.active_tab());
    }
    this._tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital', autorefresh: true, templateID: this.setTemplateId()},
      VitalPdfPrinterComponent, source_id, { source_id: this.source_id, patient_id: this.patient_id,
        source_type: this.source_type, patientVitalId: this.patientVital ? this.patientVital.id : null,
        enotePrinter: true, templateID: this.setTemplateId(), doctor_id: this.doctor_id,
        template_name: this.setDocumentName(), selectedTemplate: this.selectedRichTextTemplate, templateType: this.setTemplateType(),
        is_rich_text: this.is_rich_text, is_added_to_chart: this.add_to_chart_status()}))
  }

  setDocumentName(): string {
    return this.is_rich_text ? this.selectedRichTextTemplate.name : this._enote_service.template.name;
  }

  add_enote(template, label) {
    if (template && template.id) {
      var template_id = template.id;
    }
    this._tabs_service.add_tab(new Tab(
      {title: 'Copy Template', icon: 'fa fa-hospital', autorefresh: false},
      EnoteComponent, template_id + 1,
      {id: template_id, template: template_id, stage: label, label: label})
    )
  }

  edit_care_plan() {
    this.stage = 'drafted_enote'
    this._enote_service.template.stage = this.stage;
  }
  isNote() {
    return this.stage === 'signed_enote';
  }

  isPrintAble() {
    return this.stage === 'print_enote';
  }

  isCarePlan() {
    return this.source_type === 'ProgrammeEnrolment'
  }

  isRichTextTemplate() {
    return this.stage === 'richText'
  }
  get isReadOnly() {
    return this.stage === 'read_only_template';
  }

  richTextSignedSuccessfully(event: RichTextTemplate) {
    this._enote_service.reloadRichTextTemplate.next(event)
    this._enote_service.reloadEnotes.next(1);
  }
  add_to_chart_status() {
    if (this.is_rich_text)
    {
      return this.selectedRichTextTemplate.added_to_medical_chart ? true : false ;
    }
    if(this._enote_service.template)
    {
      return this._enote_service.template.added_to_medical_chart? true : false;
    }
    if (this.patientVital)
    {
      return this.patientVital.added_to_medical_chart ? true : false;
    }
    return false;
  }

  private setTemplateId(): number | null {
    return this.is_rich_text ? this.selectedRichTextTemplate?.id : this._enote_service.template?.id ?? null;
  }

  private setTemplateType(): string | null {
    return this.is_rich_text ? 'richText' :
      this._enote_service.template ? 'enote' :
        this.patientVital ? 'patientVital' : null;
  }
}
